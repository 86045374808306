.signup-workspace-container {
  font-family: "Poppins";
  background-color: white;
  display: flex;
  align-items: center;
}

.signup-workspace-container .left-bg {
  background-color: #5956e9;
  height: 100vh;
  width: 35vw;
}

.signup-workspace-container .main-content-container {
  height: 80vh;
  width: 80vw;
  background: hsla(0, 0%, 100%, 0.1);
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
  padding: 0;
  border-radius: 10px;
  justify-content: space-between;
  display: flex;
}

.signup-workspace-container .main-content-container .left-section-container {
  color: #fff;
  height: 100%;
  justify-content: space-between;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 45%;
  padding: 2rem 3rem;
}

.signup-workspace-container .main-content-container .left-section-container .colorful-bg {
  position: absolute;
  left: 0;
  height: 80vh;
  width: 100%;
  overflow: hidden;
  top: 0;
}

.signup-workspace-container .main-content-container .left-section-container .top {
  height: 2rem;
  align-items: center;
  display: flex;
}

.signup-workspace-container .main-content-container .left-section-container .top .eazybe-logo-img {
  height: 1.6rem;
  margin-right: 0.5rem;
}

.signup-workspace-container .main-content-container .left-section-container .top .txt {
  font-size: 18px;
  font-weight: 600;
}

.signup-workspace-container .main-content-container .left-section-container .top .txt .red-dot {
  color: #ff6e30;
  font-weight: 600;
}

.signup-workspace-container .main-content-container .left-section-container .middle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.signup-workspace-container .main-content-container .left-section-container .middle h4 {
  font-size: 1.5rem;
  font-weight: 600;
}

.signup-workspace-container .main-content-container .left-section-container .middle p {
  font-size: 12px;
}

.signup-workspace-container .main-content-container .left-section-container .bottom ul {
  width: 100%;
  list-style: disc;
  list-style-type: disc;
}

.signup-workspace-container .main-content-container .left-section-container .bottom ul li {
  font-size: 12px;
  margin-bottom: 10px;
}

.signup-workspace-container .main-content-container .left-section-container .bottom ul li::before {
  /* content: url("../../assets/img/ul-dot.png"); */
  margin-right: 9px;
}

.signup-workspace-container .main-content-container .right-section-container {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
}

.signup-workspace-container .main-content-container .right-section-container .sign-in-btn-container {
  align-self: flex-end;
  align-items: center;
}

.signup-workspace-container .main-content-container .right-section-container .sign-in-btn-container p {
  color: #41416e;
  font-size: 14px;
}

.signup-workspace-container .main-content-container .right-section-container .sign-in-btn-container .sign-in-btn {
  background: #f4f2ff;
  padding: 4px 10px;
  margin-left: 1rem;
  color: #5956e9;
  font-size: 12px;
  border-radius: 10px;
}

.signup-workspace-container .main-content-container .right-section-container .form-content {
  justify-content: center;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .accepted-invite-container {
  margin-bottom: 3rem;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .accepted-invite-container .top {
  align-items: center;
  justify-content: center;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .accepted-invite-container .top h4 {
  font-size: 1.2rem;
  font-weight: 550;
  margin-bottom: 0 !important;
  margin-right: 1rem;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .accepted-invite-container .top img {
  height: 1rem;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .accepted-invite-container .bottom {
  justify-content: center;
  align-items: center;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .accepted-invite-container .bottom p {
  color: #6E757C;
  font-size: 14px;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .header-txt {
  font-size: 1.6rem;
  font-weight: 600;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .middle {
  align-items: center;
  width: 100%;
  height: 9rem;
  justify-content: center;
  display: flex;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .middle .email-input-container {
  position: relative;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .middle .email-input-container .email-input {
  width: 28rem;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .middle .email-input-container .verify-btn {
  background: #5956e9;
  color: white;
  position: absolute;
  right: 0%;
  height: 2.5rem;
  width: 5rem;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .middle .signup-google {
  border: 1px solid #DBDBDB;
  border-radius: 10px;
  width: 28rem;
  height: 2.5rem;
  padding: 4px 2rem;
  align-items: center;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .middle .signup-google .google-logo {
  height: 1.2rem;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .middle .signup-google .txt {
  margin-left: 6rem;
}

.signup-workspace-container .main-content-container .right-section-container .form-content .tnc-container {
  font-size: 12px;
}
