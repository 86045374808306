.loader-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 4px;
    background-color: #f0f0f0;
    z-index: 9999;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .loader-bar {
    height: 100%;
    background-color: #25d366;
    transition: width 0.3s ease-in-out; /* Smooth transition */
    border-radius: 10px;
  }
  
  .loader-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    color: #6A7280;
  }

  .loader-logo {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
  }