@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');

#root {
  margin: 0 !important;
  padding: 0 !important;
}

#eazybe-root{
  width: 100%;
    height: 100%;
    z-index: 1000000;
    position: absolute;
    border: none;
    font-family: 'Poppins', sans-serif !important;
    letter-spacing: 0.6px;
}


a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  /* display: flex; */
  /* place-items: center; */
  min-width: 320px;
  min-height: 100vh;
}

