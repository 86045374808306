.otp-verify-screen-container {
    font-family: "Poppins";
    height: 80vh;
    width: 80vw;
    background: rgba(255, 255, 255, 0.1);
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
    padding: 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.otp-verify-screen-container .left-section-container {
    color: #fff;
    height: 100%;
    width: 45%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.otp-verify-screen-container .left-section-container .top {
    height: 2rem;
    align-items: center;
    display: flex;
    padding-left: 3rem;
    padding-top: 5rem;
}

.otp-verify-screen-container .left-section-container .top .eazybe-logo-img {
    height: 1.6rem;
    margin-right: 0.5rem;
}

.otp-verify-screen-container .left-section-container .top .txt {
    font-size: 18px;
    font-weight: 600;
}

.otp-verify-screen-container .left-section-container .top .txt .red-dot {
    color: #ff6e30;
    font-weight: 600;
}

.otp-verify-screen-container .left-section-container > h4 {
    font-size: 1.3rem;
    padding: 1rem;
    font-weight: 600;
    width: 80%;
}

.otp-verify-screen-container .left-section-container .team-img {
    width: 100%;
}

.otp-verify-screen-container .right-section-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.otp-verify-screen-container .right-section-container .sign-in-btn-container {
    align-self: flex-end;
    align-items: center;
}

.otp-verify-screen-container .right-section-container .sign-in-btn-container p {
    color: #41416e;
    font-size: 14px;
}

.otp-verify-screen-container .right-section-container .sign-in-btn-container .sign-in-btn {
    background: #f4f2ff;
    padding: 4px 10px;
    margin-left: 1rem;
    color: #5956e9;
    font-size: 12px;
    border-radius: 10px;
}

.otp-verify-screen-container .right-section-container .bottom {
    height: 18rem;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.otp-verify-screen-container .right-section-container .bottom .verify-txt {
    font-size: 18px;
}

.otp-verify-screen-container .right-section-container .bottom .back-btn-container {
    align-items: center;
}

.otp-verify-screen-container .right-section-container .bottom .back-btn-container .arrow-back {
    height: 14px;
    margin-right: 1rem;
}

.otp-verify-screen-container .right-section-container .bottom .back-btn-container .txt {
    font-size: 18px;
    color: #3d4043;
}

.otp-verify-screen-container .right-section-container .bottom .otp-items-container {
    height: 11rem;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.otp-verify-screen-container .right-section-container .bottom .otp-items-container .enter-otp-txt {
    color: #3d4043;
    font-size: 14px;
}

.otp-verify-screen-container .right-section-container .bottom .otp-items-container .otp-input-container .otp-input > input {
    width: 2rem !important;
    margin-right: 1rem;
    height: 2rem;
}

.otp-verify-screen-container .right-section-container .bottom .otp-items-container .otp-input-container .resend-otp-btn {
    color: #6e757c;
    font-size: 11px;
    margin-top: 8px;
    float: right;
    margin-right: 20px;
}

.otp-verify-screen-container .right-section-container .bottom .signup-btn {
    background-color: #6e6e6e;
    color: white;
    font-size: 1rem;
    padding: 9px;
    border-radius: 10px;
    width: 100%;
}

.otp-verify-screen-container .right-section-container .bottom .signup-btn-active {
    background-color: #5956e9;
}
